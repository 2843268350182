import { createRouter, createWebHistory } from "vue-router"

import Website from "../views/website/index.vue"
import Download from "../views/download/index.vue"
import AppDownload from "../views/appDownload/index.vue"
import LxDownload from "../views/lxDownload/index.vue"
import Privacy from "../views/privacy/index.vue"
import Useragreement from "../views/useragreement/index.vue"

const router =  createRouter({
  history: createWebHistory(), // 去掉url中的#
  routes: [
    {
      path: "/",
      name: "index",
      component: Website
    },
    {
      path: "/download",
      name: "download",
      component: Download
    },
    {
      path: "/appDownload",
      name: "appDownload",
      component: AppDownload
    },
    {
      path: "/lxDownload",
      name: "lxDownload",
      component: LxDownload
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy
    },
    {
      path: "/useragreement",
      name: "Useragreement",
      component: Useragreement
    }
  ]
})

export default router
