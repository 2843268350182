<template>
	<div class="page">
		<div class="header">
			<span>链接下载中心</span>
			<span>{{ getDate }}</span>
		</div>
		<ul class="body">
			<li>
				<img :src="lxUrl" alt="" />
				<p>蓝信APP</p>
				<p>随时随地，沟通不断线！</p>
			</li>
			<!-- <li>
				<img :src="appUrl" alt="" />
				<p>线索直报APP</p>
			</li> -->
			<li>
				<img :src="wxUrl" alt="" />
				<p>线索直报微信小程序</p>
				<p>发现异常就上报，人人都是安全员！</p>
			</li>
		</ul>
	</div>
</template>
  
<script>
import { parseTime } from '@/utils/index'
import { navigationList } from '@/api/index'
import QRCode from 'qrcode'
export default {
  name: 'myDownload',
	// 时间格式化
	computed: {
		getDate() {
			return parseTime(this.currentDate, '{y}-{m}-{d} {h}:{i}')
		},
	},
	data() {
		return {
			result: {
				title: '',
				xszb: '',
				app: '',
				lanxinPC: '',
				lanxinAPP: '',
				pdf: '',
				jszc: '',
				ywzc: '',
				filings: '',
			},
      lxUrl: '',
			appUrl: '',
			wxUrl: '',
			currentDate: new Date()
		}
	},
  mounted() {
    setInterval(() => {
      this.currentDate = new Date()
    }, 1000);
		navigationList().then((res) => {
			if (res.resp_code == 0) {
				const result = {}
				res.datas.forEach(item => {
					result[item.skipName] = item.skipValue
				})
				this.result = result
				QRCode.toDataURL('https://mdjf.hebeipingan.org.cn/appDownload').then((url) => {
          this.appUrl = url 
        })
				QRCode.toDataURL('https://mdjf.hebeipingan.org.cn/lxDownload').then((url) => {
          this.lxUrl = url
        })
				QRCode.toDataURL('https://mp.weixin.qq.com/a/~oDr4G37kuO8-jXtmuDyV_Q~~').then((url) => {
          this.wxUrl = url
        })
			}
		})
  },
	methods: {
		gotoAdmin() {
			window.open(this.result.xszb, '__blank')
		},
	}
}
</script>

<style lang="scss" scoped>

.page{
	position: relative;
	width: 100vw;

	.header{
		position: fixed;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100vw;
		height: 80px;
		background: rgb(88,91,232);
		padding: 32px;
		box-sizing: border-box;
		z-index: 100;

		span{
			font-size: 24px;
			color: #fff;
			margin-right: 32px;
		}
	}

	ul.body{
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 100vw;
		height: 100vh;
		background-image: url("../../assets/bg.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		box-sizing: border-box;
		padding: 0 320px;

		li{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			img{
				width: 320px;
				height: 320px;
			}
			p{
				font-size: 32px;
				color: #fff;
				text-align: center;
				margin-top: 32px;
			}
		}
	}

}

@media screen and (max-width: 1000px) {
  .header{
		height: 40px !important;
		span{
			font-size: 16px !important;
		}
	}
	.body{
		height: auto !important;
		padding: 56px 16px !important;
		
		
	}
	
}


</style>
