<template>
	<div class="page">
		<div class="header">
			<span>{{ result.title }}</span>
			<span>{{ getDate }}</span>
		</div>
		<div class="body">
			<div class="content">
				<ul>
					<li @click="gotoAdmin"> 
						<img src="../../assets/admin.jpg" alt="">
						<div class="info info1">
							<h3>风险情报信息直报系统</h3>
							<span>立即查看 →</span>
						</div>
					</li>
					<li>
						<img src="../../assets/app.jpg" alt="">
						<div class="info info2">
							<div class="app-name">
								<img src="../../assets/logo.png" alt="">
								<span>线索直报APP下载</span>
							</div> 
							<img class="code" :src="appUrl" alt="">
							<p>扫描二维码下载</p>
						</div>
					</li>
					<li @click="downloadLx">
						<img src="../../assets/lx.jpg" alt="">
						<div class="info info3">
							<div class="app-name">
								<img src="../../assets/lx.png" alt="">
								<span>蓝信</span>
							</div>
							<div>
								<img class="code" :src="lxUrl" alt="">
								<p>扫描二维码下载蓝信app</p>
							</div>
						</div>
					</li>
					<li @click="downloadPdf">
						<img src="../../assets/word.jpg" alt="">
						<div class="info info4">
							<h3>文档下载</h3>
							<span>PDF下载</span>
						</div>
					</li>
				</ul>
				<p class="tip">
					<span>技术支持电话：{{result.jszc}}</span>
					<span>业务支持电话：{{result.ywzc}}</span>
				</p>
			</div>
		</div>
		<div class="footer" v-if="result.filings">
			<p>{{result.filings}}</p>
		</div>
	</div>
</template>
  
<script>
import { parseTime } from '@/utils/index'
import { navigationList } from '@/api/index'
import QRCode from 'qrcode'
export default {
  name: 'myWebsite',
	// 时间格式化
	computed: {
		getDate() {
			return parseTime(this.currentDate, '{y}-{m}-{d} {h}:{i}')
		},
	},
	data() {
		return {
			result: {
				title: '',
				xszb: '',
				app: '',
				lanxinPC: '',
				lanxinAPP: '',
				pdf: '',
				jszc: '',
				ywzc: '',
				filings: '',
			},
			appUrl: '',
			lxUrl: '',
			currentDate: new Date()
		}
	},
  mounted() {
    setInterval(() => {
      this.currentDate = new Date()
    }, 1000);
		navigationList().then((res) => {
			if (res.resp_code == 0) {
				const result = {}
				res.datas.forEach(item => {
					result[item.skipName] = item.skipValue
				})
				this.result = result
				// QRCode.toDataURL('https://mdjf.hebeipingan.org.cn/prod-api/api-file/files/download/' + this.result.app).then((url) => {
        //   this.appUrl = url 
        // })
				// QRCode.toDataURL('https://mdjf.hebeipingan.org.cn/prod-api/api-file/files/download/' + this.result.lanxinAPP).then((url) => {
        //   this.lxUrl = url
        // })
				QRCode.toDataURL('https://mdjf.hebeipingan.org.cn/appDownload').then((url) => {
          this.appUrl = url 
        })
				QRCode.toDataURL('https://mdjf.hebeipingan.org.cn/lxDownload').then((url) => {
          this.lxUrl = url
        })
			}
		})
  },
	methods: {
		gotoAdmin() {
			window.open(this.result.xszb, '__blank')
		},

		downloadPdf() {
			window.open('https://mdjf.hebeipingan.org.cn/prod-api/api-file/files/download/' + this.result.pdf, '__blank')
		},

		downloadLx() {
			window.open('https://mdjf.hebeipingan.org.cn/prod-api/api-file/files/download/' + this.result.lanxinPC, '__blank')
		}
	}
}
</script>

<style lang="scss" scoped>

.page{
	position: relative;
	width: 100vw;

	.header{
		position: fixed;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100vw;
		height: 80px;
		background: rgb(88,91,232);
		padding: 32px;
		box-sizing: border-box;
		z-index: 100;

		span{
			font-size: 24px;
			color: #fff;
			margin-right: 32px;
		}
	}

	.body{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 100vh;
		background-image: url("../../assets/bg.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		box-sizing: border-box;

		.content{
			width: 60%;

			ul{
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
				height: 100%;

				li{
					position: relative;
					width: 48%;
					margin-bottom: 2%;

					>img{
						width: 100%;
						aspect-ratio: 2;
						border-radius: 8px;
					}
					.info{
						position: absolute;
						top: 0;
						right: 0;
						height: 100%;
						display: flex;
						justify-content: center;
						flex-direction: column;
					}

					.info1{
						width: 40%;
						color: #fff;
						h3{
							width: max-content;
							font-size: 24px;
							font-weight: 500;
							padding-bottom: 8px;
						}
						p{
							font-size: 10px;
							color: #efefef;
							padding-top: 4px;
						}
						span{
							width: max-content;
							margin-top: 32px;
							padding: 4px 12px;
							line-height: 30px;
							cursor: pointer;
							border: 1px solid #fff;
							border-radius: 32px;
							font-size: 16px;
						}
					}
					.info2{
						align-items: center;
						gap: 16px;
						top: 15%;
						right: 8%;
						width: 30%;
						height: 70%;
						border-radius: 12px;
						background: #fff;
						.app-name{
							display: flex;
							align-items: center;

							img{
								width: 24px;
								height: 24px;
								margin-right: 4px;
							}

							span{
								font-size: 12px;
								font-weight: 800;
							}
						}
						.code{
							width: 60%;
							aspect-ratio: 1;
							border: 1px solid #cfcfcf;
							border-radius: 12px;
						}
						p{
							font-size: 12px;
						}
					}
					.info3{
						gap: 16px;
						width: 30%;
						.app-name{
							display: flex;
							align-items: center;

							img{
								width: 32px;
								height: 32px;
								margin-right: 4px;
								border-radius: 4px;
							}

							span{
								font-size: 12px;
								font-weight: 800;
							}
						}

						h2{
							font-size: 20px;
						}
						h3{
							font-size: 12px;
							font-weight: 500;
							margin-top: 4px;
						}
						.code{
							width: 50%;
							aspect-ratio: 1;
							border: 1px solid #cfcfcf;
							border-radius: 12px;
						}
						p{
							font-size: 10px;
							margin-top: 4px;
						}
					}
					.info4{
						width: 35%;
						color: #fff;
						h3{
							width: max-content;
							font-size: 24px;
							font-weight: 500;
							padding-bottom: 8px;
						}
						p{
							font-size: 10px;
							color: #efefef;
							padding-top: 4px;
						}
						span{
							width: max-content;
							margin-top: 32px;
							padding: 4px 12px;
							line-height: 30px;
							cursor: pointer;
							border-radius: 32px;
							font-size: 14px;
							color: rgb(129,170,252);
							background: #fff;
						}
					}

				}
			}

			.tip span{
				margin-top: 32px;
				color: #fff;
				margin-right: 32px;
				font-size: 18px;
			}
		}
	}


	.footer{
		position: absolute;
		bottom: 16px;
		width: 100vw;
		
		p{
			color: #cfcfcf;
			font-size: 15px;
			text-align: center;
		}
	}
}

@media screen and (max-width: 1000px) {
  .header{
		height: 40px !important;
		span{
			font-size: 16px !important;
		}
	}
	.body{
		height: auto !important;
		padding: 56px 16px !important;
		
		.content{
			width: 100% !important;
			li{
				width: 100% !important;
				>img{
					border-radius: 4px !important;
				}
				.info1{
					h3{
						font-size: 12px !important;
					}
					p{
						font-size: 5px !important;
					}
					span{
						margin-top: 16px !important;
						padding: 4px 8px !important;
						line-height: 16px !important;
						font-size: 10px !important;
					}
				}
				.info2{
					gap: 8px !important;
					.app-name{
						img{
							width: 16px !important;
							height: 16px !important;
							margin-right: 4px !important;
						}

						span{
							font-size: 8px !important;
						}
					}
					.code{
						border-radius: 4px !important;
					}
					p{
						font-size: 8px !important;
					}
				}
				.info3{
					gap: 8px !important;
					.app-name{
						img{
							width: 24px !important;
							height: 24px !important;
							margin-right: 4px !important;
							border-radius: 4px !important;
						}

						span{
							font-size: 10px !important;
						}
					}

					h2{
						font-size: 10px !important;
					}
					h3{
						font-size: 7px !important;
						margin-top: 4px !important;
					}
					.code{
						border-radius: 4px !important;
					}
					p{
						font-size: 6px !important;
						margin-top: 4px !important;
					}
				}
				.info4{
					h3{
						font-size: 16px !important;
						padding-bottom: 4px !important;
					}
					p{
						font-size: 7px !important;
						padding-top: 2px !important;
					}
					span{
						margin-top: 16px !important;
						padding: 4px 8px !important;
						line-height: 12px !important;
						font-size: 8px !important;
					}
				}
			}
		}
		
		.tip span{
			display: block;
			font-size: 12px !important;
		}
	}
	.footer {
		bottom: 8px !important;
		p{
			font-size: 12px !important;
		}
	} 
	
}


</style>
