<template>
  <div class="useragreement">
    <p><br /></p>
    <p><strong>用户服务协议</strong></p>
    <p>第V1.0版本</p>
    <p>发布日期：2024年1月01日</p>
    <p>生效日期：<u>2024</u>年1月01日</p>
    <p>
      <strong>河北省委政法委员会</strong
      >（以下简称“<strong>政法委</strong>”）通过河北省委政法委员会综合平台（以下简称“平台”）向用户提供在线采集风险情报的服务。登录前请务必仔细阅读以下协议内容。
    </p>
    <p>一、用户须知</p>
    <p>
      （一）用户应当审慎阅读、充分理解《用户服务协议》（以下简称“本协议”）的所有条款，特别是协议中采用加黑、加粗等方式提请用户高度注意的内容。
    </p>
    <p>
      （二）如用户不接受本协议，请退出本平台，不使用平台提供的相关服务。如用户接受本协议，应当按照提示完成登录程序。用户阅读本协议内容并勾选我“同意《用户服务协议》、《隐私政策》”即表示用户确认对本协议全部条款含义已充分理解并完全接受。
    </p>
    <p>
      （三）用户阅读并同意本协议后，本平台可能会因国家政策以及履行本协议的环境发生变化而修改服务条款，并在平台内进行更新，新的条款一经发布即生效。用户应当在平台提示页面点击“同意”。如用户对修改后的协议存在异议，请立即停止登录或使用平台。如用户仍继续登录或使用服务的，即视为用户同意接受修订后的协议。
    </p>
    <p>二、服务范围和服务渠道</p>
    <p>
      （一）平台可提供个人风险信息采集、个人信息查看、账号密码修改等服务，具体服务范围以平台实际提供的在线业务为准。
    </p>
    <p>
      （二）根据政府主管部门、政法委服务管理需要等情况，平台有权调整个人在线业务服务范围、服务时间以及服务内容，具体以平台实际提供的在线业务服务范围为准，无需单独通知用户。
    </p>
    <p>
      （三）平台的线上服务渠道包括个人网上业务系统、移动客户端（手机App）、微信公众号、微信小程序。
    </p>
    <p>三、用户名、密码和安全</p>
    <p>
      （一）用户在申请登录或使用本平台时，包括登录账号、修改密码、风险采集上报等相关业务，用户须保证所填写的信息、提交的资料均完整、真实、准确、合法、有效，不存在与事实不符的情况。如存在虚假、隐瞒等行为，用户同意按照政法委失信行为相关管理规定进行处理，同时承担因不实声明、承诺、提供虚假信息或材料的一切后果及法律责任。
    </p>
    <p>
      （二）用户应当妥善保管自己的登录账号及密码，通过用户账号及密码成功登录并使用本平台的所有行为及办理的相关业务均将被视为用户亲自作出的行为或办理的业务，用户应当对以其账号进行的所有活动和行为负法律责任，包括直接和间接造成的责任。
    </p>
    <p>
      （三）若用户发现他人未经许可（非授权）使用其账号或存在其他账号安全问题，用户应在第一时间通知政法委，并采取合理的救济措施。
    </p>
    <p>
      （四）用户与平台在线相互传送的以符号、数字、字母、文字、图片等形式表达的，就提供服务、履行相关交易有关的各项数据信息，与书面合同或正式的书面文件具有同等法律效力。
    </p>
    <p>四、用户的禁止行为</p>
    <p>
      平台致力于为用户提供文明健康、规范有序的网络环境。如果平台发现或收到他人举报用户发布的信息违反本条约定，平台有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，平台有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用等措施并追究其法律责任。
    </p>
    <p>五、服务的变更、中断或终止</p>
    <p>
      <strong
        >（一）平台有权对用户提交的资料或信息进行核验，核验结果仅适用于用户在平台上办理相关业务或查询用户信息。如用户提供的资料不准确，或无法通过核验，或本平台有合理的理由认为该资料或信息不真实、不完整、不准确，平台有权暂停或终止用户的登录或办理其他业务的请求，并拒绝用户使用平台的服务。</strong
      >
    </p>
    <p>
      <strong
        >（二）如发生下列任何一种情形，平台有权随时暂停或终止对用户的服务，而无需对用户或任何第三方承担任何责任：</strong
      >
    </p>
    <p><strong>1.用户违反相关法律法规或本协议的约定；</strong></p>
    <p><strong>2.按照法律规定或主管部门的要求；</strong></p>
    <p><strong>3.出于安全的原因或其他必要的情形；</strong></p>
    <p>
      <strong
        >4.用户需明白，平台为了整体运营的需要或相关政策要求，在单方发布公告后，可以暂时变更、中断或终止服务，而无需向用户或第三方承担任何责任。协议的变更或终止并不代表变更或终止前发生的未完成交易指令的撤销，也不能消除因变更或终止前的交易所带来的任何法律后果。</strong
      >
    </p>
    <p>
      <strong
        >5.用户通过政法委服务网点或其他经政法委认可的渠道对个人信息：包括但不限于姓名、性别、证件类型、证件号码、联系电话等进行变更的，平台默认修改后的信息是用户的有效信息，用户应充分理解并知晓，政法委不承担任何法律责任。</strong
      >
    </p>
    <p>六、个人信息保护</p>
    <p>
      保护用户个人信息是政法委“平台”的一项基本原则，平台不对外公开提供用户登录资料及在使用网络服务时存储在平台的非公开内容。但平台可在发生下列情况时处理用户个人信息：
    </p>
    <p>（一）事先获得用户的明确授权；</p>
    <p>（二）为订立或者履行用户作为一方当事人的合同所必需；</p>
    <p>（三）为履行法定职责或者法定义务所必需；</p>
    <p>
      （四）为应对突发公共卫生事件,或者紧急情况下为保护自然人的生命健康和财产安全所必需；
    </p>
    <p>（五）依照相关法律规定在合理的范围内处理已公开的个人信息；</p>
    <p>
      （六）为公共利益实施新闻报道、舆论监督等行为,在合理的范围内处理个人信息；
    </p>
    <p>
      （七）平台有权对整个用户数据库进行用户实名制身份认证及与政府相关机构开展业务协作；
    </p>
    <p>（八）法律、行政法规规定的其他情形。</p>
    <p>七、免责声明</p>
    <p>（一）用户理解并同意，平台在以下情况下不承担责任（包括但不限于）：</p>
    <p>
      1.平台服务过程中可能出现技术性中断、服务延时及安全性问题（包括但不限于平台系统崩溃无法正常使用、电信设备故障、网络故障、电力中断、计算机病毒、木马或其他恶意程序、黑客攻击）或平台定期、不定期进行系统维护造成的损失。
    </p>
    <p>
      2.平台不允许用户冒用他人身份信息登记，否则，因此产生的法律责任将由用户承担，给平台或其第三方造成的损失，用户应给予赔偿。
    </p>
    <p>
      （二）如因用户在使用本平台时违反了上述规则而产生任何损失或损害，政法委不承担任何责任。
    </p>
    <p>八、法律适用和争议解决</p>
    <p>
      （一）如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并具有约束力。
    </p>
    <p>
      （二）本协议适用中华人民共和国法律。如因本协议发生争议，各方应友好协商，协商不成，各方同意由政法委住所地有管辖权的人民法院管辖。
    </p>
    <p>九、适用对象</p>
    <p>本协议适用于【2024】年【01】月【01】日起使用平台的用户。</p>
    <p>十、如何联系我们</p>
    <p>
      如果您对本协议有任何疑问、意见或建议，或者对我们收集、使用或披露您的个人信息有任何问题，请拨打政法委的客服电话或登录政法委官网与政法委联系。
    </p>
    <p>用户确认：本人已仔细阅读并同意上述全部协议内容，自愿使用本平台。</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>河北省委政法委员会</p>
    <p>&nbsp;</p>
  </div>
</template>
<script>
export default {
  name: 'myUseragreement'
}</script>
<style lang="scss" scoped>
.useragreement {
  padding: 5px 10px;
  font-size: 26px;
  p{
    padding: 5px 0;
  }
}
@media screen and (max-width: 1000px) {
  .useragreement {
    padding: 5px 10px;
    font-size: 16px;

    p{
      padding: 5px 0;
    }
  }
}
</style>
