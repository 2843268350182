<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      path: '/'
    } 
  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  user-select: none;
}
ul,li{
  display: inline-block;
  list-style-type: none
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
