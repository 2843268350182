<template>
  <div class="privacy">
    <p><br /></p>
    <p><strong>隐私政策</strong></p>
    <p>第V1.0版本</p>
    <p>发布日期：2024年1月1日</p>
    <p>生效日期：2024年1月1日</p>
    <p>
      <strong>河北省委政法委员会</strong
      >（以下简称“政法委”）充分尊重您的隐私权。河北省委政法委特此制定本《隐私政策》（以下简称“本政策”）以便您了解政法委如何收集、处理、使用、保护、存储及传输您的个人数据。请您仔细阅读本政策。如您有任何疑问，请告知河北省委政法委。
    </p>
    <p>
      <strong>本政策与您使用</strong>政法委<strong
        >服务息息相关，希望您在使用政法委服务前仔细阅读并确认您已经充分理解本政策所述内容，您可以按照本政策的指引做出您认为适当的选择。您开始使用或在政法委更新本政策后（政法委会根据本政策所述的方式提示您更新的情况）继续使用政法委服务，即意味着您同意本政策（含更新版本）内容。</strong
      >
    </p>
    <p>
      个人信息是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人的各种信息，包括自然人的姓名、出生日期、身份证件号码、电话号码、电子邮箱、行踪信息等。
    </p>
    <p>
      <strong
        >河北省委政法委员会综合服务平台的线上服务渠道包括网上业务平台</strong
      >、移动客户端（手机App）、微信公众号、微信小程序。
    </p>
    <p>一、政法委会如何收集和使用您的个人信息</p>
    <p>（一）政法委收集的个人信息</p>
    <p>
      您在使用政法委服务时，可能需要提供个人信息。您并非必须向政法委提供个人信息，但一些情况下，如果您选择不提供，政法委将无法为您提供相关服务，也无法回应或解决您所遇到的问题。
    </p>
    <p>
      政法委仅会出于本政策所述目的收集和使用您的个人信息。下文举例说明了政法委可能收集的个人信息：
    </p>
    <p>1.您向政法委提供的信息。</p>
    <p>
      您需要登录政法委账号才能享受某些功能或者服务。当您首次登录完善个人信息，政法委会要求您提供相应的个人信息，例如您的姓名、身份证号码、手机号码。
    </p>
    <p>2.政法委在您使用服务过程中获取的信息。</p>
    <p>
      政法委会收集您的设备信息，以及您和您的设备如何与政法委产品与服务交互的信息，此类信息包括：
    </p>
    <p>
      （1）设备及应用信息。如设备名称、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字体）。
    </p>
    <p>（2）移动网络信息。地理位置（设备定位所在区域信号发射塔识别信息）。</p>
    <p>
      （3）日志信息。当您使用政法委的服务或者查看由政法委提供的内容时，政法委会自动收集某些信息并存储在日志中，如服务的访问时间、访问次数。
    </p>
    <p>
      （4）位置信息。政法委会收集、使用并处理您设备的模糊位置或准确位置。您可在设备的设置菜单中选择关闭设备上的相应权限，拒绝共享您的位置信息。
    </p>
    <p>3.政法委在您使用服务过程中获取的设备权限。</p>
    <p>（1）读取及写入存储器权限：</p>
    <p>
      当您使用河北省委政法委员会App进行文件下载相关操作或对应用数据进行存储时，政法委会向您申请获取此权限。政法委承诺仅读取或缓存必要的信息。如您选择不开启此权限，您将无法使用与此权限相关的特定功能，但不影响您使用河北省委政法委员会App提供的其他服务。
    </p>
    <p>（2）摄像头权限及相册权限：</p>
    <p>
      当您使用人脸识别、上传档案或截图反馈功能时，您需要开启摄像头权限和/或相册权限（以您使用的具体功能为准），以便您进行实时拍摄或图片/视频上传。如您选择不开启相应权限，您将无法使用与实时拍摄或图片/视频上传相关的特定功能，但不影响您使用河北省委政法委员会App提供的其他服务。
    </p>
    <p>（3）位置信息权限：</p>
    <p>
      当您使用河北省委政法委员会App时，政法委会向您申请获取此权限，获取您的以下信息：GPS信息、WLAN接入点、蓝牙和基站等传感器信息获取到的精确地理位置信息，以及通过网络位置信息（例如基站、IP及WLAN）获取的大致地理位置（粗略位置）信息。该权限可用于服务网点定位及查询服务，如您选择不开启此权限，不影响您使用相关业务功能。
    </p>
    <p>（二）政法委会如何使用您的个人信息</p>
    <p>政法委会将您的个人信息用于以下目的：</p>
    <p>1.为您提供本平台的风险采集和查看服务；</p>
    <p>2.向您发送操作系统或应用程序更新和安装的通知；</p>
    <p>3.同步和存储您上传或下载的数据以及执行上传和下载所需的数据；</p>
    <p>4.其他征得您同意的目的。</p>
    <p>
      合作的第三方SDK服务商：当您使用河北省委政法委员会App中由第三方提供的功能时，政法委可能会接入由第三方提供的软件开发包（SDK）或调用第三方应用软件服务以实现相关功能。此时，第三方SDK服务商可能会收集您相关的个人信息。前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。为了最大程度保障您的信息安全，政法委建议您在使用任何第三方服务前先行查看其隐私条款。为保障您的合法权益，您如发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与政法委取得联系。具体第三方收集使用信息说明如下：
    </p>
    <p><strong>①高德地图</strong></p>
    <p>1） 运营方：高德软件有限公司</p>
    <p>2） 使用目的：为向您提供地址信息采集功能所需的定位服务。</p>
    <p>3）收集个人信息类型：</p>
    <p>
      •位置信息
      （经纬度、精确位置、粗略位置）：用于提供精准定位功能，无需手动输入自身地理坐标就可向应用反馈您的位置信息。
    </p>
    <p>
      •设备标识信息 (IMEI/DFA/DFV/Android
      ID/MEID/OAID/IMSI、MAC地址、硬件序列号）：用于提高定位服务准确性和成功率，追踪、排查、诊断、统计服务中的各类问题，保障我们的产品和服务安全正常运行。
    </p>
    <p>
      •应用及设备系统信息（当前应用名、应用版本号、系统属性、设备型号、操作系统）：用于提高定位服务准确性和成功率，追踪、排查、诊断、统计服务中的各类问题，保障我们的产品和服务安全正常运行。
    </p>
    <p>
      •设备信息（P地址、GNSS信息、WiFi状态、WiFi参数、WiFi列表、基站信息、信号强度的信息、蓝牙信息、传感器信息、设备信号强度信息）：用于帮助我们向您提供定位、辅助定位，实现更精准的位置信息。
    </p>
    <p>4） 隐私政策链接：</p>
    <p>https://lbs.amap.com/pages/privacy</p>
    <p>&nbsp;</p>
    <p><strong>②百度语音识别</strong></p>
    <p>1）运营方：百度在线网络技术（北京）有限公司</p>
    <p>2）使用目的：识别语音用于文本输入</p>
    <p>3）收集个人信息类型：</p>
    <p>
      •设备唯一标志，设备制造商、设备型号、设备品牌：用于追踪、排查、诊断程序的各类异常问题，帮助我们快速排查问题。
    </p>
    <p>•应用包名：用以百度sdk初始化操作，验证签名</p>
    <p>•麦克风权限：用以唤起app进行语音识别</p>
    <p>•网络权限：用于上网操作，确保程序正常执行</p>
    <p>4）相关包名</p>
    <p>com.baidu.ocr</p>
    <p>5） 隐私政策链接：</p>
    <p>https://cloud.baidu.com/doc/Agreements/s/Pjwvy27gm</p>
    <p>&nbsp;</p>
    <p>（三）公开披露</p>
    <p>政法委仅会在以下情况下，公开披露您的个人信息：</p>
    <p>1.获得您的明确同意后；</p>
    <p>
      2.基于法律或合理依据的公开披露：在法律规定、诉讼或公共利益和政府主管部门有要求的情况下，政法委可能会公开披露您的信息。
    </p>
    <p>三、政法委会如何保护您的个人信息</p>
    <p>
      政法委重视个人信息的安全并按照业内标准做法来保护您的个人信息，防止其遭到未经授权的访问、披露、使用、修改、损坏或丢失。为此，政法委特别采取了以下措施：
    </p>
    <p>
      （一）政法委采取一切合理可行的措施，确保个人信息收集的最小化，不会收集与达到目的无关的个人信息。政法委只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或法律允许；
    </p>
    <p>
      （二）政法委会使用加密技术确保数据传输和存储的机密性。政法委会使用受信赖的保护机制防止数据和存储数据的服务器遭到恶意攻击；
    </p>
    <p>
      （三）政法委会建立访问控制机制，确保只有授权人员才可访问个人信息；并且依照业务需要和人员层级，控制授权人员的数量并对授权人员做到分层级的权限管理；对个人数据的访问都会被记录日志；
    </p>
    <p>
      （四）政法委会严格甄选业务合作伙伴和服务提供商，将在个人信息保护方面的要求落实到双方的合同或考核等活动中；
    </p>
    <p>
      尽管如此，任何措施都无法做到无懈可击，也没有任何产品与服务、网站、数据传输、计算机系统、网络连接是绝对安全的。
    </p>
    <p>
      若不幸发生个人信息安全事件，政法委将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、政法委已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。政法委将以邮件、短信、推送通知等方式告知您事件相关情况，难以逐一告知个人信息主体时，政法委会采取合理、有效的方式发布公告。同时，政法委还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <p>四、如何管理您的个人信息</p>
    <p>
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，政法委保障您对自己的个人信息行使以下权利：
    </p>
    <p>（一）个人信息查询</p>
    <p>用户可在个人政法委对姓名、证件号码、手机号码进行信息查询；</p>
    <p>
      如您发现政法委收集、使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可联系政法委的客服热线或登录政法委官网与政法委联系，提出处理相应信息的申请。
    </p>
    <p>（三）个人隐私权限设置</p>
    <p>
      用户可以在设备本身操作系统中，关闭设备信息、存储权限、GPS地理位置信息、相机权限、改变同意范围或撤回您的授权。关闭授权后政法委将不再收集与这些权限相关信息。
    </p>
    <p>
      尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，政法委可能无法响应您的请求：
    </p>
    <p>1.与国家安全、国防安全直接相关的；</p>
    <p>2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>3.与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
    <p>4.有充分证据表明您存在主观恶意或滥用权力的；</p>
    <p>5.响应您的请求将导致其他个人、组织的合法权益受到严重损害的；</p>
    <p>6.涉及商业秘密的。</p>
    <p>五、政法委会如何处理未成年人的个人信息</p>
    <p>
      政法委的服务主要面向成年人，但对于使用政法委服务的未成年人信息，政法委很清楚采取额外预防措施保护其隐私和安全的重要性。政法委将不满
      18 周岁（或当地法律规定的年龄）的任何人均视为未成年人。
    </p>
    <p>
      对于不满18周岁且不具有完全民事行为能力的未成年人，需经监护人同意后方可收集其个人信息，政法委只会在法律允许、监护人明确同意或者保护未成年人所必要的情况下使用或披露。如果任何时候监护人需要访问、修改或删除与被监护人有关的个人信息，请按照第四条“如何管理您的个人信息”中介绍的方式与政法委联系。
    </p>
    <p>
      如果政法委发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关内容。
    </p>
    <p>六、第三方链接及其产品与服务</p>
    <p>
      政法委的网站、应用软件产品与服务的链接。政法委的服务也可能使用或提供来自第三方的产品或服务。
    </p>
    <p>
      所有含有第三方网站、产品与服务的链接仅为方便用户而提供。政法委对此类第三方无控制权，无法对链接内容提供任何明示和暗示的保证，此类链接也不视为政法委对链接内容的推荐或授权；政法委亦无法控制第三方的隐私或数据保护政策，此类第三方也不受到本政策的约束。
    </p>
    <p>
      <strong
        >您需自行独立判断您与此类链接的互动行为，在向第三方提交个人信息之前，请阅读并参考这些第三方的隐私政策，政法委对此不承担任何责任。</strong
      >
    </p>
    <p>七、本政策如何更新</p>
    <p>
      用户阅读并同意本政策后，本平台可能会因国家政策以及履行本政策的环境发生变化而修改服务条款，并在平台内进行更新，新的条款一经发布即生效。用户应当在平台提示页面点击“同意”。如用户对修改后的隐私政策存在异议，请立即停止登录或使用平台。如用户仍继续登录或使用服务的，即视为用户同意受修订后的《隐私政策》及特定条款的约束。
    </p>
    <p>本政策所指的重大变更包括但不限于：</p>
    <p>
      （一）政法委的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型和个人信息的使用方式；
    </p>
    <p>（二）个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p>（三）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p>
      （四）政法委负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p>（五）个人信息安全影响评估报告表明存在高风险时。</p>
    <p>八、如何联系我们</p>
    <p>
      如果您对本隐私政策有任何疑问、意见或建议，或者对政法委收集、使用或披露您的个人信息有任何问题，请拨打政法委的客服电话或登录政法委官网&nbsp;
      与政法委联系，政法委核查后会在15个工作日内反馈。
    </p>
    <p>&nbsp;</p>
  </div>
</template>
<script>
export default {
  name: 'myPrivacy'
}
</script>
<style lang="scss" scoped>
.privacy {
  padding: 5px 10px;
  font-size: 26px;
  p{
    padding: 5px 0;
  }
}
@media screen and (max-width: 1000px) {
  .privacy {
    padding: 5px 10px;
    font-size: 16px;

    p{
      padding: 5px 0;
    }
  }
}
</style>
