<template>
	<div class="page">
		<div class="download" @click="download">下载</div>
		<div class="meng" v-if="isWx && isDownload" @click="cancelDownload">
			<img src="../../assets/meng.png" alt="">
		</div>
	</div>
</template>
  
<script>
import { navigationList } from '@/api/index'
export default {
  name: 'lxDownload',
	data() {
		return {
			isWx: false,
			isDownload: false
		}
	},
	mounted() {
		const userAgent = navigator.userAgent.toLowerCase();
		this.isWx = userAgent.indexOf('micromessenger') !== -1
		navigationList().then((res) => {
			if (res.resp_code == 0) {
				const result = {}
				res.datas.forEach(item => {
					result[item.skipName] = item.skipValue
				})
				this.result = result
			}
		})
	},
	methods: {
		download() {
			if (this.isWx) {
				return this.isDownload = true
			}
			window.open('https://mdjf.hebeipingan.org.cn/prod-api/api-file/files/download/' + this.result.app, '__blank')
		},
		cancelDownload() {
			this.isDownload = false
		}
	}
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
	.page{
		width: 100vw;
		height: 100vh;
		background-image: url("../../assets/appIntro.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;

		.download{
			position: fixed;
			bottom: 32px;
			left: 50%;
			transform: translate(-50%, 0);
			width: 160px;
			height: 40px;
			line-height: 40px;
			border-radius: 20px;
			background: #245fff;
			color: #fff;
			text-align: center;
		}

		.meng{
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			z-index: 100;
			background: rgba($color: #000000, $alpha: 0.4);

			img{
				width: 100vw;
			}
		}
	}
}

</style>